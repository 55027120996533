import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.config.productionTip = false

Vue.use(ElementUI);

import Drawer from "@/components/drawer";
// eslint-disable-next-line vue/multi-word-component-names
Vue.component("drawer", Drawer)

import http from './api'
Vue.prototype.$http = http

import utils from './utils'
Vue.prototype.$utils = utils

import {mixins} from "@/mixins";
Vue.mixin(mixins)

import {EventBus} from "@/utils/event-bus";
Vue.prototype.$bus = EventBus

import VueViewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'

Vue.use(VueViewer);
VueViewer.setDefaults({
  Options: {
    'inline': true,
    'button': true,
    'navbar': true,
    'title': true,
    'toolbar': true,
    'tooltip': true,
    'movable': true,
    'zoomable': true,
    'rotatable': true,
    'scalable': true,
    'transition': true,
    'fullscreen': true,
    'keyboard': true,
    'url': 'data-source'
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
