export const mixins = {
	data() {
		return {
			questData: sessionStorage.questData ? JSON.parse(sessionStorage.questData) : {},
			questTypeList: [
				{value: 'a1', label: '单选题（A1）'},
				{value: 'a2', label: '单选题（A2）'},
				{value: 'a3a4-1', label: '单选题（A3/A4 -说明'},
				{value: 'a3a4-2', label: '单选题（A3/A4 -试题'},
				{value: 'b1-1', label: '单选题（B1）-选项'},
				{value: 'b1-2', label: '单选题（B1）-提问'},
				{value: 'c-1', label: '单选题（C）-选项'},
				{value: 'c-2', label: '单选题（C）-提问'},
				{value: 'x', label: '多选题（X）'},
				{value: 'pd', label: '判断题'},
				{value: 'tk', label: '填空题'},
				{value: 'mc', label: '名词解释题'},
				{value: 'wd', label: '问答题'},
				{value: 'al', label: '案例分析'},
				{value: 'jb', label: '化学鉴别题'},
				{value: 'js', label: '计算题'},
			],
			activeTypeValue: 'a1',
			uploadAction: 'http://49.232.137.35/ocr/identify/uploadImage',
			uploadData: {uid: 10000},
			difficultyLevelList: [
				{label: '简单', value: 0},
				{label: '中等', value: 1},
				{label: '较难', value: 2},
			],
			abc26: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
			specialSymbols: ['α', 'β', "δ", "ε", "η", "θ", "ξ", "μ", "λ", "×", "÷", "≈", "≌", '±', '√', 'γ', 'φ', 'Δ', 'Ω', 'ζ', '↑', '↓', '→', '←', 'π', 'Σ', '～'],
		}
	},
	filters: {
		filterType(e) {
			let name = ''
			/*
			*  a1:单选题(A1) a2:单选题(A2) a3a4-1:单选题(A3/A4)-说明 a3a4-2:单选题(A3/A4)-试题 b1-1:单选题(B1)-选项
			* b1-2:单选题(B1)-提问 c-1:单选题(C)-选项 c-2:单选题(C)-提问 x:多选题(X) pd:判断题 tk:填空题 mc:名词解释题
			* wd:问答题 al:案例分析题 jb:化学鉴别题
			* */
			switch (e) {
				case 'a1':
					name = '单选题（A1）'
					break
				case 'a2':
					name = '单选题（A2）'
					break
				case 'a3a4-1':
					name = '单选题（A3/A4）-说明'
					break
				case 'a3a4-2':
					name = '单选题（A3/A4）-试题'
					break
				case 'b1-1':
					name = '单选题（B1）-选项'
					break
				case 'b1-2':
					name = '单选题（B1）-提问'
					break
				case 'c-1':
					name = '单选题（C）-选项'
					break
				case 'c-2':
					name = '单选题（C）-提问'
					break
				case 'x':
					name = '多选题（X）'
					break
				case 'pd':
					name = '判断题'
					break
				case 'tk':
					name = '填空题'
					break
				case 'mc':
					name = '名词解释题'
					break
				case 'wd':
					name = '问答题'
					break
				case 'al':
					name = '案例分析'
					break
				case 'jb':
					name = '化学鉴别题'
					break
				case 'js':
					name = '计算题'
					break
				// case 16:
				// 	name = ''
				// 	break
				default:
					name = ''
			}

			return name
		}
	},

	methods: {
		/**
		 * 将二进制的数据导出为 excel
		 */
		exportResponseData(data, name) {
			let blob = new Blob([data]);
			const url = URL.createObjectURL(blob);
			// 创建隐藏的可下载链接
			var eleLink = document.createElement("a");
			eleLink.style.display = "none";
			eleLink.setAttribute("href", url); //文件下载地址
			eleLink.setAttribute("download", name); //download属性只能在同域实现
			// 触发点击
			document.body.appendChild(eleLink);
			eleLink.click();
			// 然后移除
			document.body.removeChild(eleLink);
		},

		async copyText(data) {
			await navigator.clipboard.writeText(data)
			this.$message.success('复制成功')
		},

	},
}
