<template>
  <div id="app">
    <div v-show="danVisible" class="pin-tu-dialog">
      <div id="danFile" class="flex fd-c" style="height: calc(100vh - 90px); overflow-y:auto; padding-right: 10px;">
        <img style="width: 100%;" v-for="(item, index) in danFile" :key="index" :src="item.url" alt="">
      </div>

      <div class="pin-tu-operation">
        <div class="close pointer" @click="danVisible = false">×</div>
        <div class="clear-img pointer" @click="danFile = []">清空图片</div>
        <div class="clear-img pointer" style="background: #0FB501;" @click="handlePaste('danFile')">粘贴图片</div>
      </div>
    </div>


    <div v-show="pinTuVisible" class="pin-tu-dialog" style="width: 270px;">
      <div id="pinTuFile" class="flex fd-c" style="height: calc(100vh - 80px); overflow-y:auto; padding-right: 10px;">
        <img style="width: 100%;" v-for="(item, index) in pinTuFile" :key="index" :src="item.url" alt="">
      </div>

      <div class="pin-tu-operation">
        <div class="close pointer" @click="pinTuVisible = false">×</div>
        <div class="clear-img pointer" @click="clearImg('pinTuFile')">清空图片</div>
        <div class="clear-img pointer" style="background: #0FB501;" @click="handlePaste('pinTuFile')">粘贴图片</div>
      </div>
    </div>
    <router-view/>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pinTuVisible: false,
      danVisible: false,
      pinTuFile: [],
      danFile: [],
    }
  },

  mounted() {
    this.$bus.$on('openDanPic', (e) => {
      this.danVisible = !!e
    })
    this.$bus.$on('openPinTuPic', (e) => {
      this.pinTuVisible = !!e
    })
  },

  methods: {
    clearImg(key) {
      this[key] = []
    },

    // 复制粘贴上传
    handlePaste(key) {
      navigator.clipboard.readText().then(text => {
        // 如果剪贴板中有文本，先处理文本
        console.log(text);
      }).then(() => {
        return navigator.clipboard.read();
      }).then(clipboardItems => {
        // 处理剪贴板中的项目
        for (const clipboardItem of clipboardItems) {
          console.log(clipboardItem, 'clipboardItems')
          clipboardItem.getType('image/png').then(async (blob) => {
            if (blob) {
              let file = new File([blob], new Date().getTime(), {type: blob.type});
              this[key].push({
                raw: file,
                url: URL.createObjectURL(file),
                name: file.name,
              })

              setTimeout(() => {
                document.getElementById(key).scrollTop = document.getElementById(key).scrollHeight
              }, 100)
            }
          }).catch(e => console.error(e));
        }
      }).catch(e => console.error(e));
    },
  },
}
</script>

<style lang="less">
@import "style/common.less";


.pin-tu-dialog {
  position: fixed;
  left: 0;
  bottom: 0;
  top: 0;
  width: 420px;
  padding: 3px 0 3px 3px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: #fff;
  border-right: 5px solid #01A7FF;
  z-index: 9999;

  .pin-tu-operation {
    width: 100%;
    height: 67px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 0px 0px 0px 0px;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    gap: 10px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    .close {
      width: 38px;
      flex: 0 0 38px;
      height: 38px;
      text-align: center;
      line-height: 34px;
      background: #fff;
      color: #666;
      font-size: 34px;
      border-radius: 5px;
    }

    .clear-img {
      width: 160px;
      height: 42px;
      background: #999999;
      border-radius: 5px 5px 5px 5px;
      text-align: center;
      color: #fff;
      line-height: 42px;
    }
  }
}
</style>
