import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: "login",
    component: () => import('../views/login/index.vue')
  },
  {
    path: '/',
    name: "layout",
    redirect: '/home',
    component: () => import('../views/layout.vue'),
    children: [
      {
        path: 'home',
        name: '题库生成',
        component: () => import('../views/home'),
      },
      {
        path: 'maintainQuestionBank',
        name: '维护题库',
        component: () => import('../views/home/maintainQuestionBank.vue'),
      },
      {
        path: 'uploadImages',
        name: '上传识别',
        component: () => import('../views/home/uploadImages.vue'),
      },
      // {
      //   path: 'identifyReturn',
      //   name: '识别返回',
      //   component: () => import('../views/home/identifyReturn.vue'),
      // },
    ]
  },

  {
    path: '/conversionQuestion',
    name: '识别返回',
    component: () => import('../views/home/conversionQuestion.vue'),
  },
]

const router = new VueRouter({
  routes
})

export default router
