import axios from "axios";
import qs from "qs";
import {Message} from "element-ui";
import router from "../router";
import store from "../store";
import constant from './constant'

console.log(process.env, "process.env");

let http = axios.create({
	baseURL: constant.BASE_URL,
	timeout: 1800000,
	responseType: "json",
	headers: {
		"Content-Type": "application/json"
	}
});

// 请求qs序列化
http.interceptors.request.use(
	(config) => {
		const token = sessionStorage.getItem("token");
		if (
			config.method === "post" &&
			config.headers["Content-Type"] === "application/x-www-form-urlencoded"
		) {
			config.data = qs.stringify(config.data, {
				arrayFormat: "indices",
				allowDots: true,
			});
		}
		if (token) {
			config.headers.Authorization = token;
		}
		return config;
	},
	(error) => {
		Message({
			showClose: true,
			message: error && error.data.error_msg,
			type: "error",
		});
		return Promise.reject(error.data.error_msg);
	}
);

// 接收数据，刷新token，封装res.bus_json
http.interceptors.response.use(
	(res) => {
		console.log(res, 'interceptors.response')
		switch (res.data.code) {
			case 500:
				Message.error(res.data.desc)
				return res
			default:
				return res
		}
	},
	(error) => {
		console.log(error, 'err')
		if (error.response.data.status === 401) {
			router.replace('/')

			return Promise.reject(error.response);
		}
		Message.error({
			showClose: true,
			message: error.response.data.message,
		});
		return Promise.reject(error.response);
	}
);

export default http;
