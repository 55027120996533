import req from "@/api/http";
// import store from "../store";

export default {
  // 获取验证码
  sendVerCode: (data) =>
    req({
      url: "sms/sendVerCode",
      method: "get",
      params: data,
    }),

  // 登录
  login: (data) =>
    req({
      url: "auth/login",
      method: "post",
      params: data,
    }),

  getQuest: (data) =>
    req({
      url: "quest/list",
      method: "get",
      params: data,
    }),

  saveQuest: (data) =>
    req({
      url: "quest/save",
      method: "post",
      params: data,
    }),

  editQuest: (data) =>
    req({
      url: "quest/update",
      method: "post",
      params: data,
    }),

  delQuest: (data) =>
    req({
      url: "quest/delete",
      method: "post",
      params: data,
    }),

  saveQuestItem: (data) =>
    req({
      url: "questItem/save",
      method: "post",
      params: data,
    }),

  getQuestItem: (data) =>
    req({
      url: "questItem/list",
      method: "get",
      params: data,
    }),

  saveCorrelation: (data) =>
    req({
      url: "questItem/saveCorrelation",
      method: "post",
      params: data,
    }),

  deleteCorrelation: (data) =>
    req({
      url: "questItem/deleteCorrelation",
      method: "post",
      params: data,
    }),

  delQuestItem: (data) =>
    req({
      url: "questItem/delete",
      method: "post",
      params: data,
    }),

  editQuestItem: (data) =>
    req({
      url: "questItem/update",
      method: "post",
      params: data,
    }),

  insertQuestItem: (data) =>
    req({
      url: "questItem/insertQuestItem",
      method: "post",
      params: data,
    }),

  getTotalQuestItemCount: (data) =>
    req({
      url: "quest/getTotalQuestItemCount",
      method: "post",
      params: data,
    }),

  exportExcel: (data) =>
    req({
      url: "quest/exportExcel",
      method: "post",
      params: data,
      responseType: 'blob',
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      }
    }),

  exportImageExcel: (data) =>
    req({
      url: "quest/exportImageExcel",
      method: "post",
      params: data,
      responseType: 'blob',
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      }
    }),

  exportImage: (data) =>
    req({
      url: "quest/exportImage",
      method: "post",
      params: data,
      responseType: 'blob',
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      }
    }),

  exportExcelNoBlob: (data) =>
    req({
      url: "quest/exportExcel",
      method: "post",
      params: data,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      }
    }),

  exportImageExcelNoBlob: (data) =>
    req({
      url: "quest/exportImageExcel",
      method: "post",
      params: data,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      }
    }),

  exportImageNoBlob: (data) =>
    req({
      url: "quest/exportImage",
      method: "post",
      params: data,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      }
    }),



};
