<template>
  <el-drawer
      :title="drawerTitle"
      :visible.sync="drawerVisible"
      :direction="direction"
      :before-close="handleClose"
  >
    <div class="drawer-box">
      <div class="line"></div>
      <slot></slot>
      <div class="submit-btn pointer" @click="onsubmit">{{ submitBtnText }}</div>
    </div>
  </el-drawer>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Drawer",
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    drawerTitle: {
      type: String,
      default: ''
    },
    submitBtnText: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      drawerVisible: false,
      direction: 'rtl',
    };
  },
  watch: {
    visible: {
      deep: true,
      handler(n) {
        this.drawerVisible = n
      }
    }
  },
  methods: {
    handleClose(done) {
      done()
      this.$emit('update:visible', false)
    },

    onsubmit() {
      this.$emit('submit')
    },
  }
}
</script>

<style scoped lang="less">
/deep/.el-drawer {
  border-left: 5px solid #01A7FF;
  background: #F8F8F8;
  min-width: 500px;

  .el-drawer__header {
    span {
      font-size: 18px;
      color: #292929;
      font-weight: bold;
    }
  }

  .drawer-box {
    padding: 0 20px 20px;

    .line {
      width: 100%;
      height: 1px;
      background: #ccc;
      margin-bottom: 18px;
    }

    .submit-btn {
      width: 100%;
      height: 36px;
      background: #01A7FF;
      border-radius: 5px 5px 5px 5px;
      color: #fff;
      text-align: center;
      line-height: 36px;
      font-size: 16px;
      margin-top: 15px;
    }

  }
}

</style>
